#dd-builder {
  margin-bottom: 10px;
  box-sizing: border-box;
  line-height: 1.3;
}

.trailerCTA {
  text-align: center;
}

.trailerCTA1 {
  font-weight: 900;
  font-size: 24px;
  margin-bottom: 10px;
}

.trailerCTA2 {
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 10px;
}

#dd-summary *, #dd-builder *, #modal-container * {
  box-sizing: border-box;
  line-height: 1.6;
}

#dd-builder {
  /* Trailer classes */
  /*
  .builder-details-panel + .builder-details-options {
    display:none;
  }
  .builder-details-panel.open + .builder-details-options {
    display:block;
  }*/
  /* Closed */
  /* Open */
}
#dd-builder h1 {
  font-size: 3rem;
  line-height: 1.3;
}
#dd-builder h2 {
  font-size: 2rem;
  line-height: 1.3;
}
#dd-builder a {
  text-decoration: underline;
}
#dd-builder #trailerbrand {
  text-align: center;
  padding-top: 10px;
}
#dd-builder .trailerbrandlink {
  color: #d40001;
  font-size: 0.8rem;
  text-decoration: none;
}
#dd-builder .trailerclasses {
  display: flex;
  flex-direction: column;
}
#dd-builder .trailerclass {
  border: 1px solid #dccdba;
  padding: 15px;
  border-radius: 2px;
  color: #d40001;
  text-align: center;
  margin: 5px;
  cursor: pointer;
}
#dd-builder .trailerclass-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}
#dd-builder .trailerclass-logo {
  background-color: #d40001;
  flex: 0 0 50px;
  border-radius: 5px;
}
#dd-builder .trailerclass-header {
  flex: 1 1 auto;
}
#dd-builder .trailerclass-logo img {
  max-width: 50px;
}
#dd-builder .trailerclass-header {
  font-size: 1.1rem;
  font-weight: bold;
}
#dd-builder .trailerclasses img {
  max-width: 100%;
}
@media only screen and (min-width: 768px) {
  #dd-builder .trailerclasses {
    flex-direction: row;
    align-items: stretch;
  }
  #dd-builder .trailerclass {
    flex: 1 1 33%;
  }
}
#dd-builder .builder-badge-link {
  cursor: pointer;
}
#dd-builder .builder-bade a {
  text-decoration: none;
}
#dd-builder .builder-selector-model {
  font-size: 1em;
  font-weight: normal;
  color: #333;
  padding: 20px 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #000;
  cursor: pointer;
  border: 1px solid #dccdba;
}
#dd-builder .builder-details-photo {
  display: block;
  float: left;
  margin: 5px 10px 5px 0;
  width: 160px;
  height: 120px;
  overflow: hidden;
  cursor: pointer;
}
#dd-builder .builder-details-photo img {
  max-width: 100%;
}
#dd-builder .builder-details-photo:hover {
  border: 1px solid #dccdba;
}
#dd-builder .builder-details-floorplan-link {
  font-weight: bold;
}
#dd-builder .trailer-selector-model h3 {
  font-size: 1.5em;
}
#dd-builder .builder-selector-model-img {
  float: right;
  max-width: 200px;
}
#dd-builder .builder-details-floorplan-img {
  max-width: 100%;
  margin: 0 auto;
}
#dd-builder .builder-details-tabselector {
  border: 1px solid #333;
  border-radius: 10px;
  display: flex;
  padding: 0 10px;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}
#dd-builder .builder-details-tab {
  margin: 15px 10px;
  flex: 1 1 auto;
  text-align: center;
}
#dd-builder .builder-details-tab a {
  font-weight: bold;
}
#dd-builder .builder-details-tab.open a {
  opacity: 0.7;
}
#dd-builder .builder-details-tab:last-child {
  border-right: 0px;
}
#dd-builder .builder-details-tabcontent {
  display: none;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
#dd-builder .builder-details-tabcontent.open {
  display: block;
}
#dd-builder .builder-details-pane {
  background-color: #EEE;
  margin-bottom: 10px;
}
#dd-builder .builder-overview {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
@media (max-width: 767px) {
  #dd-builder .builder-overview {
    flex-direction: column;
  }
}
@media (max-width: 767px) {
  #dd-builder .builder-overview-image, #dd-builder .builder-overview-overview {
    flex: 1 1 100%;
  }
}
@media (min-width: 768px) {
  #dd-builder .builder-overview-image, #dd-builder .builder-overview-overview {
    flex: 1 1 50%;
  }
}
#dd-builder .builder-overview-image img {
  max-width: 100%;
}
#dd-builder .builder-details-footercustomize {
  clear: both;
  padding-top: 20px;
}
#dd-builder .builder-details-panel {
  font-size: 20px;
  padding: 30px 10px;
  font-weight: bold;
  cursor: pointer;
  border: 1px solid #dccdba;
  display: flex;
  height: 70px;
  align-items: center;
}
#dd-builder .builder-details-panel .b-label {
  flex: 1 1 100%;
}
#dd-builder .builder-details-panel .b-toggle {
  flex: 0 0 auto;
}
#dd-builder .builder-option-label {
  font-weight: bold;
  color: #9e0b0f;
}
#dd-builder .builder-option-label[data-description] {
  cursor: pointer;
  text-decoration: underline;
}
#dd-builder .builder-details-options {
  padding: 20px 0 20px 10px;
}
#dd-builder .builder-option-label-price {
  color: #444;
  font-size: 75%;
  padding-left: 10px;
}
#dd-builder .builder-details-panel .b-toggle .b-open {
  display: block;
}
#dd-builder .builder-details-panel .b-toggle .b-close {
  display: none;
}
#dd-builder .builder-details-panel.open .b-toggle .b-open {
  display: none;
}
#dd-builder .builder-details-panel.open .b-toggle .b-close {
  display: block;
}
#dd-builder .builder-form-option {
  margin: 5px 0;
  display: flex;
  flex-direction: row;
}
#dd-builder .builder-form-col1 {
  flex: 0 0 50px;
  text-align: right;
}
#dd-builder .builder-form-col2 {
  flex: 1 1 auto;
  text-align: left;
  padding-left: 10px;
}
#dd-builder .builder-basecost {
  font-weight: bold;
  font-size: 120%;
  margin: 10px 0;
}

#dd-summary {
  margin-bottom: 20px;
}

.builder-actionbutton, .builder-actionbutton:hover {
  color: #fff;
  font-size: 25px;
  padding: 12px 0;
  text-align: center;
  width: 100%;
  display: block;
  margin: 20px 0 10px;
  text-decoration: none;
  border: 1px solid #9e0b0f;
  -moz-box-shadow: 3px 3px 10px -5px #000;
  -webkit-box-shadow: 3px 3px 10px -5px #000;
  box-shadow: 3px 3px 10px -5px #000;
  background-image: linear-gradient(bottom, #900 25%, #d11118 65%);
  background-image: -o-linear-gradient(bottom, #900 25%, #d11118 65%);
  background-image: -moz-linear-gradient(bottom, #900 25%, #d11118 65%);
  background-image: -webkit-linear-gradient(bottom, #900 25%, #d11118 65%);
  background-image: -ms-linear-gradient(bottom, #900 25%, #d11118 65%);
  background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0.25, #900), color-stop(0.65, #d11118));
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  width: 75%;
  margin: 0 auto;
}

#dd-summary {
  /* Summary */
}
#dd-summary .summary-title {
  font-size: 34px;
}
#dd-summary .summary-feature {
  color: #4d3222;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: bold;
}
#dd-summary .summary-needhelp-header {
  font-size: 34px;
  text-align: center;
}
#dd-summary .summary-needhelp {
  color: #4d3222;
  font-size: 18px;
  text-align: center;
}
#dd-summary .summary-input {
  width: 100%;
  margin: 3px 0;
  padding: 15px;
  border: 1px solid #888;
  border-radius: 3px;
  background-color: #fff;
}
#dd-summary .summary-input::placeholder {
  color: #444;
}
#dd-summary .summary-textarea {
  width: 100%;
  margin: 3px 0;
  padding: 15px;
  border: 1px solid #888;
  border-radius: 3px;
}
#dd-summary .summary-category {
  font-weight: bold;
  font-size: 18px;
}
#dd-summary .summary-choice {
  margin: 5px 0;
  font-size: 16px;
  color: #4d3222;
}
#dd-summary .summary-totalrow {
  display: flex;
  align-items: center;
}
#dd-summary .summary-totaltext {
  flex: 1 1 auto;
  font-size: 18px;
  font-weight: bold;
}
#dd-summary .summary-subtotal {
  font-size: 34px;
  font-weight: bold;
}
#dd-summary .summary-taxnotincluded {
  text-align: right;
  color: #4d3222;
}
#dd-summary .summary-notfinished {
  text-align: center;
  font-weight: bold;
  margin-top: 15px;
  font-size: 18px;
}
#dd-summary .summary-instructions {
  text-align: center;
}
#dd-summary .summary-submitbutton,
#dd-builder .summary-submitbutton {
  cursor: pointer;
  color: #fff;
  font-size: 25px;
  padding: 12px 0;
  text-align: center;
  width: 100%;
  display: block;
  margin: 20px 0 10px;
  text-decoration: none;
  border: 1px solid #9e0b0f;
  -moz-box-shadow: 3px 3px 10px -5px #000;
  -webkit-box-shadow: 3px 3px 10px -5px #000;
  box-shadow: 3px 3px 10px -5px #000;
  background-image: linear-gradient(bottom, #900 25%, #d11118 65%);
  background-image: -o-linear-gradient(bottom, #900 25%, #d11118 65%);
  background-image: -moz-linear-gradient(bottom, #900 25%, #d11118 65%);
  background-image: -webkit-linear-gradient(bottom, #900 25%, #d11118 65%);
  background-image: -ms-linear-gradient(bottom, #900 25%, #d11118 65%);
  background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0.25, #900), color-stop(0.65, #d11118));
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

/* Utility */
.builder-badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin: 4px 0;
}

.builder-badge-danger {
  color: #fff;
  background-color: #e3342f;
}

.builder-badge-link {
  color: #9e0b0f;
  background-color: #ebe0ce;
  border: 1px solid #dccdba;
}

#modal-container {
  position: fixed;
  opacity: 0;
  display: none;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  z-index: 5000;
}

#modal-container.open {
  display: block;
  opacity: 1;
}

#modal-container #modal-contact {
  position: relative;
  background-color: #EEE;
  padding: 30px;
  width: 90%;
  border-radius: 5px;
  margin: 5vh auto;
  z-index: 5000;
}
#modal-container #modal-infobox {
  position: relative;
  background-color: #EEE;
  padding: 15px;
  width: 90%;
  border-radius: 5px;
  margin: 5vh auto;
  z-index: 5000;
}
#modal-container .series-arrow {
  width: 30px;
  height: 30px;
}
#modal-container .modal-infobox-image, #modal-container .modal-infobox-iframe {
  max-width: 100%;
  max-height: 350px;
  margin: 0px auto;
  display: block;
}
#modal-container .modal-infobox-webiframe {
  max-width: 100%;
  width: 100%;
  height: 500px;
  margin: 0px auto;
  display: block;
}
#modal-container .series-arrow {
  cursor: pointer;
}
#modal-container .series-arrow-left {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translate(0, -50%);
}
#modal-container .series-arrow-right {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(0, -50%);
}
@media (min-width: 768px) {
  #modal-container #modal-infobox {
    width: 60%;
  }
}
#modal-container #modal-overlay {
  background-color: #000;
  opacity: 0.6;
  z-index: 4999;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
#modal-container .modal-input-text {
  width: 100%;
  margin: 3px 0;
  padding: 15px;
  border: 1px solid #888;
  border-radius: 3px;
  background-color: #FFF;
  color: #000;
}
#modal-container .modal-input-text::placeholder {
  color: #333;
}
#modal-container .modal-input-textarea {
  width: 100%;
  margin: 3px 0;
  padding: 15px;
  border: 1px solid #888;
  border-radius: 3px;
  background-color: #FFF;
  color: #000;
}
#modal-container .modal-input-textarea::placeholder {
  color: #333;
}
#modal-container .contactform-header {
  display: flex;
  justify-content: center;
  align-items: center;
}
#modal-container .contactform-inputbox, #modal-container .contactform-selectbox {
  width: 100%;
  color: #af2906;
  font-weight: bold;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}
#modal-container #modal-close {
  color: #F00;
  font-size: 16px;
  float: right;
  cursor: pointer;
  font-weight: bold;
}
#modal-container #modal-header {
  font-size: 40px;
  line-height: 1.2;
  margin-bottom: 10px;
  flex: 1 1 auto;
  font-weight: bold;
}
#modal-container .modal-submitbutton {
  color: #fff;
  font-size: 25px;
  padding: 12px 0;
  text-align: center;
  width: 100%;
  display: block;
  margin: 20px 0 10px;
  text-decoration: none;
  border: 1px solid #9e0b0f;
  -moz-box-shadow: 3px 3px 10px -5px #000;
  -webkit-box-shadow: 3px 3px 10px -5px #000;
  box-shadow: 3px 3px 10px -5px #000;
  background-image: linear-gradient(bottom, #900 25%, #d11118 65%);
  background-image: -o-linear-gradient(bottom, #900 25%, #d11118 65%);
  background-image: -moz-linear-gradient(bottom, #900 25%, #d11118 65%);
  background-image: -webkit-linear-gradient(bottom, #900 25%, #d11118 65%);
  background-image: -ms-linear-gradient(bottom, #900 25%, #d11118 65%);
  background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0.25, #900), color-stop(0.65, #d11118));
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
@media (min-width: 768px) {
  #modal-container .modal-summary-instructions {
    text-align: center;
    width: 75%;
    margin: 0px auto;
  }
}
@media (max-width: 767px) {
  #modal-container #modal-header {
    font-size: 20px;
  }
}

/*
.modal-gallery a {
  display: block;
  border: 1px solid #dccdba;
  float: left;
  margin: 5px 10px 5px 0;
  width: 160px;
  height: 120px;
  overflow: hidden;
}*/

/*# sourceMappingURL=builder.css.map */
